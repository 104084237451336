import React from "react"
import { Box, Grid, Typography, Stack } from "@mui/material"
import { Link } from "gatsby"
import slugify from "slugify"
import { Code, colors, Textarea } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import { Image } from "../../../../features/submodules/components/image"
import { Headline } from "../../../../features/submodules/components/headline"

const components = {
  headline: props => (
    <Box>
      <Box
        id={slugify(`${props.headline}`).toLowerCase()}
        // Position anchor a bit higher because of fixed header
        sx={{ transform: "translateY(-100px)" }}
      />
      <Headline {...props} />
    </Box>
  ),
  code: ({ text }) => (
    <Box
      sx={{
        p: 3,
        border: 1,
        borderColor: colors.border[700],
        borderRadius: "1rem",
      }}
    >
      <Code language="python">{text}</Code>
    </Box>
  ),
  images: ({ images, caption, columnsmobile, columnsdesktop }) => (
    <Box sx={{ py: { xs: 2, sm: 4 } }}>
      <Grid container spacing={4}>
        {images &&
          images.map((image, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12 / columnsmobile}
                md={12 / Math.ceil((columnsmobile + columnsdesktop) / 2)}
                lg={12 / columnsdesktop}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    borderRadius: "1rem",
                    overflow: "hidden",
                  }}
                >
                  <Image {...image} />
                </Box>
              </Grid>
            )
          })}
      </Grid>

      {caption && (
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            mt: 2,
            fontStyle: "italic",
            a: { color: colors.primary[900] },
          }}
        >
          {parse(caption)}
        </Typography>
      )}
    </Box>
  ),
  content: ({ text }) => <Textarea linkComponent={Link}>{text}</Textarea>,
}

const FlexibleContent = props => {
  const { flex } = props

  return (
    <Stack spacing={2}>
      {flex &&
        flex.map(({ id: fieldId, fieldGroupName, ...rest }, index) => {
          const id = fieldId || fieldGroupName?.split("_").pop().toLowerCase()

          if (typeof components?.[id] !== "function") {
            return null
          }

          return <Box key={index}>{components[id](rest)}</Box>
        })}
    </Stack>
  )
}

export default FlexibleContent
