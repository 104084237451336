import React from "react"
import { Textarea } from "@parallel-domain/pd-theme"

export type Props = {
  host: string
  id: string
  backgroundVideo?: number
}

const Video = (props: Props) => {
  const { host, id, backgroundVideo = 0 } = props

  if (!host || !id) {
    return null
  }

  const renderIframe = () => {
    switch (host) {
      case "vimeo":
        return (
          <Textarea sx={{ "div.iframe-wrapper": { mb: 0 } }}>
            {`
            <iframe 
              src="https://player.vimeo.com/video/${id}?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&background=${backgroundVideo}" 
              width="1280"
              height="720"  
              frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen
            ></iframe>
            `}
          </Textarea>
        )

      case "youtube":
        return null
    }
  }

  return renderIframe()
}

export default Video
