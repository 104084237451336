import imageConfig from "../image/config"
import headlineConfig from "../headline/config"

const config = [
  {
    id: "flex",
    label: "Blocks",
    type: "flexible_content",
    items: [
      {
        id: "headline",
        label: "Headline",
        fields: headlineConfig[0].fields,
      },
      {
        id: "code",
        label: "Code",
        fields: [
          {
            id: "text",
            type: "textarea",
            label: "Text",
            rows: 10,
          },
        ],
      },
      {
        id: "content",
        label: "Wysiwyg",
        fields: [
          {
            id: "text",
            type: "wysiwyg",
            label: "Text",
          },
        ],
      },
      {
        id: "images",
        label: "Images",
        fields: [
          {
            id: "columnsdesktop",
            type: "number",
            label: "Columns Desktop",
            defaultValue: 3,
          },
          {
            id: "columnsmobile",
            type: "number",
            label: "Columns Mobile",
            defaultValue: 1,
          },
          {
            id: "images",
            type: "repeater",
            label: "Images",
            items: [...imageConfig[0].fields],
          },
          {
            id: "caption",
            type: "text",
            label: "Caption",
          },
        ],
      },
    ],
  },
]

export default config
