import React from "react"
import { Box, Typography, Container, Stack } from "@mui/material"
import parse from "html-react-parser"
import { Textarea } from "@parallel-domain/pd-theme"

// import app components
import Reveal from "../../../../components/reveal"
import ContactForm from "./ContactForm"
import ContactForm2 from "./ContactForm2"
import NewsletterForm from "./NewsletterForm"

const Form = props => {
  const { headline, text, buttontext = "Submit", form } = props

  const renderForm = () => {
    switch (form) {
      case "contact":
        return <ContactForm buttonText={buttontext} />

      case "contact2":
        return <ContactForm2 buttonText={buttontext} />

      case "newsletter":
        return <NewsletterForm buttonText={buttontext} />
    }
  }

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth={form.startsWith("contact") ? "md" : "sm"}>
        {(headline || text) && (
          <Stack
            spacing={2}
            sx={{ mb: { xs: 2, sm: 6 }, alignItems: "center" }}
          >
            {headline && (
              <Reveal>
                <Typography variant="h2">{parse(headline)}</Typography>
              </Reveal>
            )}

            {text && (
              <Reveal>
                <Textarea>{text}</Textarea>
              </Reveal>
            )}
          </Stack>
        )}

        {renderForm()}
      </Container>
    </Box>
  )
}

export default Form
