import React from "react"
import { Box, Typography, Container, Stack } from "@mui/material"
import parse from "html-react-parser"
import { colors, Textarea } from "@parallel-domain/pd-theme"

// import app components
import { Image, ImageType } from "../../../submodules/components/image"
import { Button, ButtonType } from "../../../submodules/components/button"
import Reveal from "../../../../components/reveal"

type Props = {
  subline: string
  headline: string
  text: string
  buttons: { button: ButtonType }[]
  image: ImageType
  position: string
}

const Banner = (props: Props) => {
  const {
    subline,
    headline,
    text,
    buttons,
    image,
    position = "initial",
  } = props

  return (
    <Box
      sx={{
        py: [3, 4, 5],
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        position,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {image && (
          <Image
            {...image}
            sx={{
              height: "100%",
              ".gatsby-image-wrapper": {
                height: "100%",
                width: "100%",
              },
            }}
          />
        )}
      </Box>

      <Container maxWidth="lg">
        <Stack spacing={2} sx={{ textAlign: "center" }}>
          {subline && (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Reveal background={colors.primary[700]}>
                <Typography variant="subtitle2" color="primary">
                  {parse(subline)}
                </Typography>
              </Reveal>
            </Box>
          )}

          {headline && (
            <Reveal width="100%">
              <Typography variant="h1" component="h2">
                {parse(headline)}
              </Typography>
            </Reveal>
          )}

          {text && (
            <Reveal width="100%">
              <Container maxWidth="md">
                <Textarea>{text}</Textarea>
              </Container>
            </Reveal>
          )}

          {buttons && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "center" }}
            >
              {buttons.map((o, index: number) => (
                <Reveal key={index} delay={0.5 + 0.25 * index}>
                  <Button {...o.button.link} variant={o.button.variant} />
                </Reveal>
              ))}
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default Banner
